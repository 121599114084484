@import 'variables.scss';

html, body, #root {
  height: 100%;
}
body {
  color: $color-text;
  background-color: $color-bg-default;
  font-family: 'Archivo Narrow', sans-serif;
  font-weight: 400 !important;
  font-size: 16px;
}
.wrapper {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  &:before {
    content: '';
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../images/bg/pexels-photo-136419-lowres.jpg');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
  }
  header {
    position: relative;
    z-index: 2;
    flex: 0 0 auto;
    .title {
    }
    nav {
      border-top: solid 5px transparent;
      ul.nav-pills {
        display: flex;
        justify-content: center;
        li {
          display: inline-block;
          &>a {
            color: $color-link;
            background-color: transparent;
            border-radius: 0;
            padding-top: 0.5em;
            padding-bottom: 0.5em;
            &:hover, &:focus, &:active, &:visited {
              color: $color-link;
            }
          }
          &.active>a {
            color: $color-link;
            background-color: rgba($color-text-light, 0.75);
            &:hover, &:focus, &:active, &:visited {
              color: $color-link;
            }
          }
        }
      }
    }
  }
  main {
    position: relative;
    z-index: 4;
    flex: 1 0 auto;
    padding: 2em 1em;
    background-color: rgba($color-text-light, 0.75);
    section {
      padding: 0;
      h1 { display: none; }
    }
  }
  footer {
    position: relative;
    z-index: 3;
    flex: 0 0 auto;
    background-color: rgba($color-text-light, 0.75);
  }
}
a, a:focus, a:active, a:visited {
  color: $color-link;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: $color-text-light;
    background-color: $color-link;
  }
}
.center {
  text-align: center;
}
.pswp-thumbnails {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .pswp-thumbnail {
    flex: 0 1 auto;
    width: 320px;
    padding: 0.5em;
    img {
      width: 100%;
      height: auto;
      cursor: pointer;
    }
  }
}
.download-item {
  display: block;
  color: $color-text;
  padding: 0.5em;
  border-radius: 3px;
  margin: 1em 0;
  background-color: rgba($color-text, 0);
  transition-property: background-color;
  transition-duration: .2s;
  transition-timing-function: linear;
  &:hover, &:active {
    text-decoration: none;
    background-color: rgba($color-text, 1);
    transition-property: background-color;
    transition-duration: .2s;
    transition-timing-function: linear;
  }
  figure {
    display: flex;
    flex-direction: row;
    img {
      width: 40px;
      height: 40px;
    }
    figcaption {
      padding-left: 0.5em;
    }
  }
}
